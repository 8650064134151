@import url('https://fonts.googleapis.com/css2?family=Playpen+Sans:wght@200&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Caveat+Brush&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&family=Caveat+Brush&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&family=Caveat+Brush&family=Quicksand:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");

@font-face {
  font-family: "galgani";
  src: local("galgani"), url(./components/fonts/Gagalin-Regular.otf) format("opentype");
}
@font-face {
  font-family: "gliker";
  src: local("gliker"), url(./components/fonts/Gliker-RegularCondensed.ttf) format("truetype");
}
body {
  margin: 0;
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 300;
  background-image: linear-gradient(24deg,rgba(121, 174, 184, 0.199)4%,rgba(121, 174, 184, 0.199)100%), url(./components/images/fondoWeb6.jpg);
  background-size:2000px;
  background-repeat: repeat;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#imagenterapiasuperior {
  transform: rotate(7deg);
  border-radius: 50%;
}
#imagenterapiainferior {
  transform: rotate(-7deg);
  border-radius: 50%;  
}
#logoImg {
  width: 10rem;
}
#logoImgFooter {
  width: 4vw;
  margin-top: 3%;
}
.iconSize {
  width: 4%;
}
.iconSizeContact {
  width: 6%;
}
.iconSizeFoot {
  width: 40%;
}
.iconSizeFootFace {
  width: 80%;
}
#bannerHeader {
  font-family: galgani;
  font-size: 3vw
}
#bannerHeaderSub {
  font-family: "Caveat Brush", cursive;
  font-size: 1.5vw;
}
.subtitles {
  font-family: "Archivo Black";
  font-style: normal;
  font-size: 3vw;
}
.subtitles2{
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}
.textCustom{
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.customBtn {
  font-family: gliker;
  font-style: normal;
  font-size: 1.5vw;
}
#aviso1 {
  transform: rotate(20deg);
  width: 100%;
}
#aviso2 {
  transform: rotate(-20deg);
  width: 100%;
}
#aviso3 {
  transform: rotate(20deg);
  width: 100%;
}
#citasContacto{
  background-color: rgba(95, 110, 121, .3);
}
#contactoContacto{
  background-color: rgba(169, 202, 225, .5);
}
.myBtn:hover{
  color: rgba(36, 145, 164, 1);
  border-color: rgba(36, 145, 164, 1);
  background-color: rgba(0, 0, 0, 0);
}
.myBtn{
  color: white;
  background-color: rgba(36, 145, 164, 1);
  border-color: rgba(36, 145, 164, 1);
}
.myBtnSwal{
  color: white !important;
  background-color: rgba(36, 145, 164, 1) !important;
  border-color: rgba(36, 145, 164, 1) !important;
}
.myCustomCardTop{
  background-color: rgba(227, 238, 240);
}
.myCustomCardBottom{
  background-color:rgba(227, 238, 240);
}
.myCustomCardContainer{
  border-radius: 90%;
}
.myCustomBlogCard{
  background-color:rgba(166, 213, 217) ;
}
.myCustomFaqAnswer{
  background-color: rgba( 36, 145, 164,.5);
  color: white;
 }
.imgAviso{
  scale: 1.6;
}
.myCustomCredito{
  color:gray;
  font-size: xx-small;
}
.aboutRowStyle{
  text-align: justify;
  text-justify: inter-word;
}
.aboutBadgePsychology{
  width: 20%;
  margin-top: -2.5%;
}
.avisosContainer{
  margin-bottom: 6rem;
}
.parrafoBanner{
  text-align: justify;
}
.imgBanner{
  border-radius: 360px;
  width: 70%;
}
.imgBlogTitulo{
  width: 40%;
}
.blogCard{
  width: 20rem;
}
.contactoSubtitles{
  text-align: center;
  text-justify: inter-word;
}
.contactoCita{
  text-align: justify;
  text-justify: inter-word;
}
.faqRow{
  padding-bottom: 40px;
}
.faqImg{
  width: 55%;
}
.footerRow{
  background-color: rgba(36, 145, 164, 1);
}
#footer{
  color: white;
}
.headerRow{
  margin-top: 3rem;
}
.navbarStyle{
  background-color: rgba(36, 145, 164, 1);
}
.navBarLi{
  color: white;
}
.orientacionPadresText{
  text-align: justify;
  text-justify: inter-word;
}
.orientacionProfImg{
  width: 20%;
}
.cardServices{
  width: 20rem;
}
.SupervisionProf{
  text-align: justify;
  text-justify: inter-word;
}
.terapiaTitle{
  padding-top: 80px;
  padding-bottom: 40px;
}
.terapiaImg{
  border-radius: 45px;
}
.terapiaText{
  text-align: justify;
  text-justify: inter-word;
}
.terapiaImgCard{
  width: 50%;
}
.aboutImg{
  width: 50%;
}
.serviceCard{
  transition: transform 1s;
}
.serviceCard:hover{
transform: scale(1.1);
}
#bannerAnimation{
  position: absolute;
  left: -25%;
  transform: translateX(-50%);  
  top: 40%;
  transform: translateY(-50%);
  height: 100%;
  width: 150%;
  opacity: 0.15;
}
.aboutUsPhoto{
  background-color: transparent;
  border-style: none;
  width: 40%;
}
.categoryList{
  font-size:small;
  font-weight: bolder;
}
.menuCategoria{
font-size: small;
font-weight: bolder;
}
.customToggler{
  width: 50%;
}
/* <----------------------Media query changes-------------------------> */
/* @media (max-width:576px) { */
  @media (max-width:768px) {
  #logoImg {
    width: 36vw;
  }
  .lauraCespedesHeader{
    font-size:x-large;
  }
  .iconHeader{
    width: 60%;
  }
  .iconHeaderFace{
    width: 80%;
  }
  .footerDevSign {
    font-size: 2vw;
  }
  .customBtn{
    font-size: large;
  }
  .iconSize {
    width: 4%;
  }

  .iconSizeContact {
    width: 10%;
  }

  .iconSizeFoot {
    width: 25%;
  }
  .iconSizeFootFace{
    width: 50%;
  }
  .subtitles{
    font-size: x-large;
  }
  .imgBlogTitulo{
    width: 60%;
  }
  .faqImg{
    width: 90%;
  }
  .aboutImg{
    width: 90%;
    justify-self:center;
    align-self: center;
    justify-items: center;
    margin-top: -10%;
    margin-left: 5%;
  }
  .aboutBadgePsychology{
    width: 50%;
  }
  .imgAviso{
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom:25%;
  }
  #aviso1, #aviso2, #aviso3{
    width: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  #imagenterapiasuperior{
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    
  }
  #imagenterapiainferior{
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }
  .imgOrientacion{
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }
  .orientacionProfImg{
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }
  .terapiaImg{
    border-radius: 35px;
  }
  .serviceCard:hover{
    transform: scale(1);
    }
    #bannerAnimation{
      visibility: hidden;
    }
    .aboutUsPhoto{
      width: 100%;
    }
}